import React, { useEffect, useState } from "react";
import axios from "axios";
import env from "../../config";
import Header from '../../Header/Header';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import LeftNav from '../../Components/LeftNav/LeftNav';
import { Grid, Card, CardContent, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useLocation, useNavigate } from "react-router-dom";
import LoaderDashboard from '../../Components/LoaderDashboard';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import CheckIcon from '@mui/icons-material/Check';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';


const useStyles = makeStyles((theme) => ({
    root: {
        padding: '20px !important',
    },
    card: {
        height: '100% !important',
        display: 'flex !important',
        flexDirection: 'column !important',
        justifyContent: 'space-between !important',
        backgroundColor: '#f5f5f5',
        boxShadow: '0 3px 5px rgba(0,0,0,0.2) !important',
        transition: 'transform 0.3s ease-in-out !important',
        cursor: 'pointer',
        '&:hover': {
            transform: 'scale(1.05) !important',
        },
    },
    cardContent: {
        zIndex: 1,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '0 !important'
    },
    cardTitle: {
        fontSize: '14px !important',
        fontFamily: 'Segoe UI Historic !important',
        width: '100% !important',
        color: '#ffffff !important',
        borderBottom: '2px solid #ffffff',
        paddingBottom: '5px',
        // textAlign: 'center',
        textTransform: 'uppercase',
        paddingLeft: "3px !important"
    },
    bottomRow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        padding: "5px"

    },
    cardNumber: {
        fontSize: '2.5rem !important',
        color: '#ffffff !important',
        fontFamily: 'Segoe UI Historic !important',
    },
    iconOverlay: {
        fontSize: '50px !important',
        color: '#ffffff7a',
    },
}));



function MainDashboard() {
    const navigate = useNavigate(); // Initialize useNavigate hook for navigation
    const classes = useStyles();

    // Initialize state for surveys, loading status, and status counts
    const [surveys, setSurveys] = useState([]);
    const [loading, setLoading] = useState(true);
    const [statusCount, setStatusCount] = useState(null);

    const [filteredSurveys, setFilteredSurveys] = useState(null);
    const statusSelectionOrder = ["Approved", "Completed", "In-Progress", "Rejected", "New"];


    // Handle navigation to the survey details view
    const handleClickOn = (item) => {
        navigate('/viewsurveys', {
            state: {
                flow: item,
                surveys: filteredSurveys
            },
        })
    }
    useEffect(() => {
        fetchSurveys();

    }, []);
    useEffect(() => {
        filterSurveys();
    }, [surveys]);


    // Function to fetch surveys data from the backend API
    const fetchSurveys = async () => {
        try {
            let response = []
            if (localStorage.getItem('role') === "Admin" || localStorage.getItem('role') === "CertPro Admin" || localStorage.getItem('role') === "Project Manager") {
                response = await axios.get(env.backend + "/api/survey");
            }
            else {
                const userId = localStorage.getItem('userFullName')
                response = await axios.get(`${env.backend}/api/survey/getSurveys/${userId}`);
            }
            const nonDeletedSurveys = Object.values(response.data).filter(survey => survey.surveyStatus !== "Deleted");
            setSurveys(nonDeletedSurveys);
            filterSurveys();

            setLoading(false);
        } catch (error) {
            // Handle errors during the fetch request
            console.error("Error fetching surveys:", error);
            setLoading(true); // Set loading to true in case of error
            // alert("error fetching surveys")
            localStorage.clear()
            window.location.replace('/')
        }
    };
    const userRole = localStorage.getItem('role');

    const filterSurveys = () => {
        const surveyCounts = {
            Approved: 0,
            Completed: 0,
            Active: 0,
            Rejected: 0,
        };

        // Array to store multiple entries for the same survey under different statuses
        const transformedSurveys = [];

        surveys.forEach(survey => {
            // Group certificates by their statuses
            const statusCertificates = {
                Approved: [],
                Completed: [],
                Active: [],
                Rejected: [],
            };

            survey.surveyCertificateList.forEach(cert => {
                if (cert.surveyCertificateStatus === "Rejected") {
                    statusCertificates.Rejected.push(cert);
                } else if (cert.surveyCertificateStatus === "Approved") {
                    statusCertificates.Approved.push(cert);
                } else if (cert.surveyCertificateStatus === "Completed") {
                    statusCertificates.Completed.push(cert);
                } else if (
                    cert.surveyCertificateStatus === "New" ||
                    (cert.surveyCertificateStatus === "In-Progress")
                ) {
                    statusCertificates.Active.push(cert);
                }
            });


            // Create separate survey entries for each status
            Object.keys(statusCertificates).forEach(status => {
                if (statusCertificates[status].length > 0) {
                    transformedSurveys.push({
                        ...survey,
                        surveyStatus: status,
                        surveyCertificateList: statusCertificates[status],
                    });
                    surveyCounts[status]++;
                }
            });
        });


        // Remove duplicates by unique survey ID, status, and survey name
        // const uniqueSurveysSet = new Set();
        // const uniqueSurveys = transformedSurveys.filter(survey => {
        //     const key = `${survey.surveyId}-${survey.surveyName}-${survey.surveyStatus}`;
        //     if (uniqueSurveysSet.has(key)) {
        //         return false;
        //     }
        //     uniqueSurveysSet.add(key);
        //     return true;
        // });

        // // Sort surveys based on status priority and creation date
        // const sortedSurveys = uniqueSurveys.sort((a, b) => {
        //     const statusOrderComparison = statusSelectionOrder.indexOf(b.surveyStatus) - statusSelectionOrder.indexOf(a.surveyStatus);
        //     if (statusOrderComparison !== 0) {
        //         return statusOrderComparison;
        //     }
        //     return new Date(b.updatedOn) - new Date(a.updatedOn);
        // });

        setFilteredSurveys(transformedSurveys);
        setStatusCount(surveyCounts);
    };



    return (
        <div>
            <Header
            />
            {/* <Filtermenu /> */}
            <div className="container-fluid">
                <Tabs className="vertical-tabs">
                    <TabList className="hidden-tab-list" >
                        <Tab className="hidden-tab-list"></Tab>
                    </TabList>
                    <LeftNav />
                    <TabPanel style={{ width: "87%" }}>
                        <div class="card-listing-section">
                            {/* <h2 class="active-menu ml-3">Listings</h2> */}
                            <div class="ml-3">
                                <div class="right-wrapper" style={{ minHeight: '85dvh' }}>
                                    <div class="cyantitle">
                                        <h1 class="heading-dash" >Dashboard</h1>
                                    </div>
                                    {
                                        loading ? (<LoaderDashboard />) : (
                                            <div>

                                                <Grid container spacing={3} className={classes.root}>
                                                    {userRole === "Surveyor" ? (
                                                        // Non-clickable Grid
                                                        <>

                                                            <Grid item xs={12} sm={6} md={3}>
                                                                <Card className={classes.card} sx={{ backgroundColor: '#4d8eda !important' }}>
                                                                    <CardContent className={classes.cardContent}>
                                                                        <Typography className={classes.cardTitle} component="div">
                                                                            Active
                                                                        </Typography>
                                                                        <div className={classes.bottomRow}>
                                                                            <StarBorderIcon className={classes.iconOverlay} />
                                                                            <Typography className={classes.cardNumber} color="textSecondary">
                                                                                {statusCount?.Active}
                                                                            </Typography>
                                                                        </div>
                                                                    </CardContent>
                                                                </Card>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} md={3}>
                                                                <Card className={classes.card} sx={{ backgroundColor: '#149f83 !important' }}>
                                                                    <CardContent className={classes.cardContent}>
                                                                        <Typography className={classes.cardTitle} component="div">
                                                                            Completed
                                                                        </Typography>
                                                                        <div className={classes.bottomRow}>

                                                                            <ThumbUpOffAltIcon className={classes.iconOverlay} />

                                                                            <Typography className={classes.cardNumber} color="textSecondary">
                                                                                {statusCount?.Completed}
                                                                            </Typography>
                                                                        </div>

                                                                    </CardContent>
                                                                </Card>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} md={3}>
                                                                <Card className={classes.card} sx={{ backgroundColor: '#9259a8 !important' }}>
                                                                    <CardContent className={classes.cardContent}>
                                                                        <Typography className={classes.cardTitle} component="div">
                                                                            Approved
                                                                        </Typography>
                                                                        <div className={classes.bottomRow}>
                                                                            <CheckIcon className={classes.iconOverlay} />
                                                                            <Typography className={classes.cardNumber} color="textSecondary">
                                                                                {statusCount['Approved']}
                                                                            </Typography>
                                                                        </div>
                                                                    </CardContent>
                                                                </Card>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} md={3}>
                                                                <Card className={classes.card} sx={{ backgroundColor: '#efa231 !important' }}>
                                                                    <CardContent className={classes.cardContent}>
                                                                        <Typography className={classes.cardTitle} component="div">
                                                                            Rejected
                                                                        </Typography>
                                                                        <div className={classes.bottomRow}>
                                                                            <ThumbDownOffAltIcon className={classes.iconOverlay} />
                                                                            <Typography className={classes.cardNumber} color="textSecondary">
                                                                                {statusCount?.Rejected}
                                                                            </Typography>
                                                                        </div>
                                                                    </CardContent>
                                                                </Card>
                                                            </Grid>

                                                        </>
                                                    ) : (
                                                        // Clickable Grid (default)
                                                        <>

                                                            <Grid item xs={12} sm={6} md={3}>
                                                                <Card className={classes.card} onClick={() => handleClickOn("Active")} sx={{ backgroundColor: '#4d8eda !important' }}>
                                                                    <CardContent className={classes.cardContent}>
                                                                        <Typography className={classes.cardTitle} component="div">
                                                                            Active
                                                                        </Typography>
                                                                        <div className={classes.bottomRow}>
                                                                            <StarBorderIcon className={classes.iconOverlay} />
                                                                            <Typography className={classes.cardNumber} color="textSecondary">
                                                                                {statusCount?.Active}
                                                                            </Typography>
                                                                        </div>
                                                                    </CardContent>
                                                                </Card>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} md={3}>
                                                                <Card className={classes.card} onClick={() => handleClickOn("Completed")} sx={{ backgroundColor: '#149f83 !important' }}>
                                                                    <CardContent className={classes.cardContent}>
                                                                        <Typography className={classes.cardTitle} component="div">
                                                                            Completed
                                                                        </Typography>
                                                                        <div className={classes.bottomRow}>

                                                                            <ThumbUpOffAltIcon className={classes.iconOverlay} />

                                                                            <Typography className={classes.cardNumber} color="textSecondary">
                                                                                {statusCount?.Completed}
                                                                            </Typography>
                                                                        </div>

                                                                    </CardContent>
                                                                </Card>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} md={3}>
                                                                <Card className={classes.card} onClick={() => handleClickOn("Approved")} sx={{ backgroundColor: '#9259a8 !important' }}>
                                                                    <CardContent className={classes.cardContent}>
                                                                        <Typography className={classes.cardTitle} component="div">
                                                                            Approved
                                                                        </Typography>
                                                                        <div className={classes.bottomRow}>
                                                                            <CheckIcon className={classes.iconOverlay} />
                                                                            <Typography className={classes.cardNumber} color="textSecondary">
                                                                                {statusCount?.Approved}
                                                                            </Typography>
                                                                        </div>
                                                                    </CardContent>
                                                                </Card>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} md={3}>
                                                                <Card className={classes.card} onClick={() => handleClickOn("Rejected")} sx={{ backgroundColor: '#efa231 !important' }}>
                                                                    <CardContent className={classes.cardContent}>
                                                                        <Typography className={classes.cardTitle} component="div">
                                                                            Rejected
                                                                        </Typography>
                                                                        <div className={classes.bottomRow}>
                                                                            <ThumbDownOffAltIcon className={classes.iconOverlay} />
                                                                            <Typography className={classes.cardNumber} color="textSecondary">
                                                                                {statusCount?.Rejected}
                                                                            </Typography>
                                                                        </div>
                                                                    </CardContent>
                                                                </Card>
                                                            </Grid>

                                                        </>
                                                    )}
                                                </Grid>
                                            </div>
                                        )}
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                </Tabs>
            </div>


        </div >
    );
}

export default MainDashboard