import React, { useEffect, useState } from 'react';
import { Grid, Typography, Paper, Box } from '@mui/material';
import { useLocation, useNavigate } from "react-router-dom";
import Header from '../../../Header/Header';
import LeftNav from '../../LeftNav/LeftNav';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
} from '@mui/material';

const ViewMoreSurvey = () => {
  const location = useLocation();
  const navigate = useNavigate();


  // Define the data directly in the component
  const [data, setData] = useState(location.state?.survey);
  const flow = location.state?.flow;

    // Handler to navigate back to the surveys view with the current flow state
  const handleClose = () => {
    navigate('/viewsurveys', {
      state: {
        flow: flow,
        surveys:location.state?.surveys,
      },
    })

    };
    // // Effect to filter survey certificates based on the flow value
    // useEffect(() => {
    //     // Only filter if the flow is 'Rejected'
    // if (flow === "Rejected") {
    //   const filteredData = data.surveyCertificateList.filter((cert) => cert.comment)
    //   setData((prevData) => ({
    //     ...prevData,
    //     surveyCertificateList: filteredData
    //   }));
    // }
    // }, [])
    // Function to format a date string into 'DD/MM/YYYY' format
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return '';
    }
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  return (
    <div>
      <header>
        <Header />
      </header>
      <div className="container-fluid">
        <Tabs className="vertical-tabs" style={{ height: 'fit-content' }}>
          <TabList className="hidden-tab-list" >
            <Tab className="hidden-tab-list"></Tab>
          </TabList>
          <LeftNav />
          <TabPanel style={{ width: "87%" }}>
            <main>
              <div className="container-fluid-buyer">
                <div class="card-listing-section">
                  {/* <h2 class="active-menu ml-3">Listings</h2> */}
                  <div class="right-wrapper right-wrapper-ex">
                    <div >

                      <div className="header-container">
                        <h1><div className="heading-survey" >Survey Details</div></h1>
                      </div>
                      <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={1} style={{ marginLeft: '2px' }}>

                          <Grid item xs={4}>
                            <Grid container spacing={1}>
                              <Grid item xs={6}>
                                <Typography variant="h6">Id</Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant="body5" class="table-grid-new-more">: {data?.surveyId}</Typography>
                              </Grid>

                              <Grid item xs={6}>
                                <Typography variant="h6">Name</Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant="body5" class="table-grid-new-more">: {data?.surveyName}</Typography>
                              </Grid>

                              <Grid item xs={6}>
                                <Typography variant="h6">Status</Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant="body5" class="table-grid-new-more">: {flow}</Typography>
                              </Grid>

                              <Grid item xs={6}>
                                <Typography variant="h6">Vessel Type</Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant="body5" class="table-grid-new-more">: {data?.vesselId}</Typography>
                              </Grid>


                            </Grid>
                          </Grid>

                          <Grid item xs={4}>
                            <Grid container spacing={1}>
                              <Grid item xs={6}>
                                <Typography variant="h6">Vessel Name</Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant="body5" class="table-grid-new-more">: {data?.vesselName}</Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant="h6">Company Name</Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant="body5" class="table-grid-new-more">: {data?.companyName}</Typography>
                              </Grid>

                              <Grid item xs={6}>
                                <Typography variant="h6">Country Name</Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant="body5" class="table-grid-new-more">: {data?.countryName}</Typography>
                              </Grid>

                              <Grid item xs={6}>
                                <Typography variant="h6">Created On</Typography>
                              </Grid>
                              <Grid item xs={6}>
                                 <Typography variant="body5" class="table-grid-new-more">: {data.createdOn ? new Intl.DateTimeFormat('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }).format(new Date(data.createdOn)) : ''}</Typography>
                              </Grid>


                            </Grid>
                          </Grid>
                          <Grid item xs={4}>
                            <Grid container spacing={1}>
                              <Grid item xs={6}>
                                <Typography variant="h6">Created By</Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant="body5" class="table-grid-new-more">: {data?.createdBy}</Typography>
                              </Grid>

                              <Grid item xs={6}>
                                <Typography variant="h6">Updated On</Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant="body5" class="table-grid-new-more">: {data.updatedOn ? new Intl.DateTimeFormat('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }).format(new Date(data.updatedOn)) : ''}</Typography>
                              </Grid>
                              <Grid item xs={6}>
                              <Typography variant="h6">Updated By</Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant="body5" class="table-grid-new-more">: {data?.updatedBy}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>

                      <Grid item xs={12}>
                        <div class="table-head-new-more">Templates</div>
                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead>
                              <TableRow class="table-new-head">
                                <TableCell sx={{ paddingLeft: '13px' }} className='table-head-new'>Template Name</TableCell>
                                <TableCell className='table-head-new'>Template Status</TableCell>
                                <TableCell className='table-head-new'>Created By</TableCell>
                                <TableCell className='table-head-new'>Created On</TableCell>
                                <TableCell className='table-head-new'>UpdatedBy</TableCell>
                                <TableCell className='table-head-new'>Updated On</TableCell>
                                <TableCell className='table-head-new'>Issued Date</TableCell>
                                <TableCell className='table-head-new'>Expiry Date</TableCell>
                                <TableCell className='table-head-new'>Comment</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody sx={{ height: '24px','& td': {  padding: '0 8px',height: '24px',  } }} >
                              {data?.surveyCertificateList?.map((certificate, index) => (
                                <TableRow  key={index}>
                                      <TableCell className='table-cell-new'>{certificate.certificateName}</TableCell>
                                      <TableCell className='table-cell-new'>{certificate.surveyCertificateStatus}</TableCell>
                                      <TableCell className='table-cell-new'>{certificate.createdBy}</TableCell>
                                      <TableCell className='table-cell-new'>{certificate.createdOn ? new Intl.DateTimeFormat('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }).format(new Date(certificate.createdOn)) : ''}</TableCell>
                                      <TableCell className='table-cell-new'>{certificate.updatedBy}</TableCell>
                                      <TableCell className='table-cell-new'>{certificate.updatedOn ? new Intl.DateTimeFormat('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }).format(new Date(certificate.updatedOn)) : ''}</TableCell>
                                      <TableCell className='table-cell-new'>{certificate.issuedDate ? new Intl.DateTimeFormat('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }).format(new Date(certificate.issuedDate)) : ''}</TableCell>
                                      <TableCell className='table-cell-new'>{certificate.expiryDate ? new Intl.DateTimeFormat('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }).format(new Date(certificate.expiryDate)) : ''}</TableCell>
                                      <TableCell className='table-cell-new'>{certificate.comment}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                      <br></br>
                      <Grid item xs={12}>
                      <div class="table-head-new-more" >Surveyors</div>
                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead>
                              <TableRow class="table-new-head">
                                <TableCell className='table-head-new'>Surveyor Id</TableCell>
                                <TableCell className='table-head-new'>User Id</TableCell>
                                <TableCell className='table-head-new'>Created By</TableCell>
                                <TableCell className='table-head-new'>Created On</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody sx={{ height: '24px','& td': {  padding: '0 8px',height: '24px',  } }} >
                              {data?.surveyorsList?.map((surveyor, index) => (
                                <TableRow key={index}>
                                      <TableCell className='table-cell-new'>{surveyor.surveyorId}</TableCell>
                                      <TableCell className='table-cell-new'>{surveyor.userId}</TableCell>
                                      <TableCell className='table-cell-new'>{surveyor.createdBy}</TableCell>
                                      <TableCell className='table-cell-new'>{surveyor.createdOn ? new Intl.DateTimeFormat('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }).format(new Date(surveyor.createdOn)) : ''}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                      <div class="btn-container-multiple">
                        <button
                          class="modal-close-btn" onClick={handleClose}>
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </TabPanel>
        </Tabs>
      </div >
    </div >
  );
};

export default ViewMoreSurvey;