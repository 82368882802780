import React, { useEffect, useState,useRef } from 'react';
import '../Css/Header.css'
import logourl from '../Assets/logo_new.png';
import logoneo from '../Assets/logoneo.jpg'

import { useContext } from "react";
import AuthContext from "../Context/authProvider";
import { useNavigate } from "react-router";


const Header = () => {
    const { auth } = useContext(AuthContext);
    const [showMenu, setShowMenu] = useState(false);
    const dropdownRef = useRef(null);

    const navigate = useNavigate();
    

    useEffect(() => {
      console.log(auth)
    })

    const onLogoutClick = () => {
      localStorage.clear()
      window.location.replace('/')
    }
    const toggleMenu = () => {
      setShowMenu(!showMenu);
    };

    useEffect(() => {
      const handleOutsideClick = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setShowMenu(false);
        }
      };
  
      document.addEventListener('click', handleOutsideClick);
  
      return () => {
        document.removeEventListener('click', handleOutsideClick);
      };
    }, []);
  
     
    return (
        <div className='header' style={{ height: '47px'}}>
        <img src={logourl} alt='NEOCAP' style={{ width: '140px', height: '62px',opacity:"85%" }} />
         <div className='butn' ><img src={logoneo} alt='NEOCAP' style={{ width: '145px', height: '35px',marginTop:'7px' }} />


</div>
        <div>
        {localStorage.getItem('accessToken') && 
        <div className='header-content'>
          <div ref={dropdownRef}>
          <button className='header-avatar' onClick={toggleMenu} >
            <span className='header-avatar-img'></span>
            <span className='header-avatar-name'>Hi, {localStorage.getItem('userId') ? localStorage.getItem('userId') : 'Guest'}</span>
          </button>
          {showMenu && 
            <div
            className='logout-dropdown'
            >
              <p className='logout-option' onClick={onLogoutClick}>

              Logout
              </p>
            </div>
          }
          </div>
        </div>
        }
          </div>
      </div>
    );
  };
  

  
  
  
  
  
export default Header;