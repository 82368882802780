import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Header from "../../Header/Header";
import LeftNav from "../../Components/LeftNav/LeftNav";
import LoaderDashboard from "../../Components/LoaderDashboard";
import EditIcon from '@material-ui/icons/Edit';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Paper, MenuItem, Select, FormControl, InputLabel, Checkbox, ListItemText,
    OutlinedInput, Button, Tooltip, IconButton, Grid, TextField
} from '@mui/material';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import env from "../../config";

function ViewCertificates() {
    const navigate = useNavigate();
    const location = useLocation();
    const [surveys, setSurveys] = useState([]);
    const [status, setStatus] = useState(location.state?.status);
    const surveyId = location.state?.survey?.surveyId;
    const flow = location.state?.flow;
    const surveyName = location.state?.survey?.surveyName;
    const vesselName = location.state?.survey?.vesselName;
    const vesselId = location.state?.survey?.vesselId;
    const companyName = location.state?.survey?.companyName;
    const flagPath = location.state?.survey?.flagPath;
    const goback = location.state?.from?.pathname || "/";
    const certeditorsurveyor = location.state?.from?.pathname || "/cert-editor-surveyor";
    const topdfpreviewer = location.state?.from?.pathname || "/finalized-pdf-previewer";
    const tosavedpdfpreviewer = location.state?.from?.pathname || "/saved-pdf-previewer";
    const completelink = location.state?.from?.pathname || "/approved-finalized-pdf-previewer";
    const [filteredItems, setFilteredItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedCertificates, setSelectedCertificates] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredSurveys, setFilteredSurveys] = useState([]);
    const [surveyIssueDate, setSurveyIssueDate] = useState([]);
    const [hasFetchedSurveys, setHasFetchedSurveys] = useState(false);


    useEffect(() => {
        // handleToken();
        // surveySorting();
        fetchSurveys();
        // filterItems();

    }, []);
    useEffect(() => {
        filterItems();
        if (!hasFetchedSurveys) {
            fetchSurveys();
            setHasFetchedSurveys(true);
        }
    }, [surveys]);



    const surveySorting = () => {
        const filteredCertificates = surveys?.surveyCertificateList.filter((certificate) => {
            return certificate.surveyCertificateStatus === status;
        });
        setSurveys((prevSurveys) => ({
            ...prevSurveys,
            surveyCertificateList: filteredCertificates
        }));

    }
    const filterItems = () => {
        let filteredItems = [];
        if (flow === "Approval") {
            filteredItems = surveys?.surveyCertificateList
                ?.filter(surveyCertificateItem =>
                ((surveyCertificateItem.surveyCertificateStatus === "Approved" ||
                    surveyCertificateItem.surveyCertificateStatus === "Completed") && surveyCertificateItem.surveyCertificateStatus != "Deleted")
                )
        }
        else {
            filteredItems = surveys?.surveyCertificateList?.filter(surveyCertificateItem => surveyCertificateItem.surveyCertificateStatus !== "Approved" && surveyCertificateItem.surveyCertificateStatus != "Deleted");
        }
        filteredItems = filteredItems?.filter(surveyCertificateItem => surveyCertificateItem.surveyCertificateStatus !== "Deleted")
        const NewfilteredItems = filteredItems?.sort((a, b) => new Date(b.updatedOn) - new Date(a.updatedOn));
        // console.log("sortsss",filteredItems)
        setFilteredItems(NewfilteredItems);
        setSurveyIssueDate(surveys?.issueDate)
        setFilteredSurveys(NewfilteredItems);
    }
    const fetchSurveys = async () => {
        try {
            const response = await axios.get(env.backend + "/api/survey/" + surveyId);

            console.log("getby id", (response.data));
            setSurveys(response.data)

            //   setSurveys(Object.values(response.data));
            // setSurveys(items)
            //   setLoading(false);
        } catch (error) {
            console.error("Error fetching surveys:", error);
            setLoading(true);
            // alert("error fetching surveys")
            localStorage.clear()
            window.location.replace('/')
        }
    };

    const handleCreate = () => {
        console.log("status", status);
    }

    const handlePDFPreview = (rowData) => {
        console.log("PDF Preview", rowData.certificateName);
        console.log(flow)

        // if (flow === "Approval" || flow === "SurveyorCompleted") {

        handlePDF(rowData.surveyCertificateId, rowData.certificateName, flow, rowData.surveyCertificateStatus, rowData);
        // } else{
        //     handlePDFSave(rowData.surveyCertificateId, rowData.certificateName, "In-Progress");
        // }

    };
    const handleButtonClick = (rowData) => {
        console.log("Button clicked with editor data:", rowData);
        console.log(flow)

        /*if (flow === "Approval" || flow === "SurveyorCompleted") {
          handlePDF(rowData.surveyCertificateId, rowData.certificateName, flow);
        } else {
          handleHTML(rowData.surveyCertificateId, rowData.certificateName);
        }*/

        handleHTML(rowData.surveyCertificateId, rowData.certificateName, rowData.surveyCertificateStatus, rowData);
    };

    const handleHTML = (surveyCertificateId, certificateName, certStatus, rowData) => {
        try {
            console.log(surveyName);
            console.log(surveyId);
            console.log(companyName);
            console.log(certificateName);
            console.log(flagPath);

            navigate(certeditorsurveyor, {
                state: {
                    surveycertificateid: surveyCertificateId,
                    surveycertificatename: certificateName,
                    company: companyName,
                    surveyname: surveyName,
                    countryName: surveys?.countryName,
                    surveyid: surveyId,
                    vesselname: vesselName,
                    vesselid: vesselId,
                    flagpath: flagPath,
                    status: certStatus,
                    completionDate: surveys?.completionDate,
                    validUntilDate: surveys?.validUntilDate,
                    issuePlace: rowData?.issuePlace || surveys?.issuePlace,
                    issueDate: surveys?.issueDate,
                    certIssuedDate: rowData?.issuedDate,
                    certExpiryDate: rowData?.expiryDate,
                    survey: surveys,
                    flow: flow,
                    filterOptions: location.state?.filterOptions,
                    comment: rowData?.comment,

                },
            });
        } catch (e) {
            console.log("errored");
            console.log(e);
        }
    };
    const handlePDF = (surveyCertificateId, certificateName, flow, certStatus, rowData) => {
        if (certificateName && certificateName !== null) {
            certificateName = certificateName.replace(".html", ".pdf");
        }
        let link = ""
        if ((certStatus === "Approved") && flow === "Approval") {
            link = completelink
        }
        else
        // if ((certStatus === "Completed") && flow === "Approval")
        {
            link = topdfpreviewer
        }
        // else {
        //     link = tosavedpdfpreviewer
        // }


        console.log(certificateName);

        try {
            navigate(link, {
                state: {
                    company: companyName,
                    surveyid: surveyId,
                    surveyname: surveyName,
                    vesselname: vesselName,
                    vesselid: vesselId,
                    surveycertificateid: surveyCertificateId,
                    surveycertificatename: certificateName,
                    flow: flow,
                    status: status,
                    survey: surveys,
                    filterOptions: location.state?.filterOptions,
                    rowData: rowData,
                },
            });
        } catch (e) {
            console.log("errored");
            console.log(e);
        }
    };
    const handleClose = () => {
        if (flow === "Approval") {
            navigate('/approver-dashboard', {
                state: {
                    filterOptions: location.state?.filterOptions
                }
            })
        } else {
            navigate('/surveyor-dashboard', {
                state: {
                    filterOptions: location.state?.filterOptions
                }
            })
        }
    };

    const handlePDFSave = (surveyCertificateId, certificateName, flow) => {
        if (certificateName && certificateName !== null) {
            certificateName = certificateName.replace(".html", ".pdf");
        }

        console.log(certificateName);

        try {
            navigate(tosavedpdfpreviewer, {
                state: {
                    company: companyName,
                    surveyid: surveyId,
                    surveyname: surveyName,
                    vesselname: vesselName,
                    vesselid: vesselId,
                    surveycertificateid: surveyCertificateId,
                    surveycertificatename: certificateName,
                    flow: "no-back",
                    status: status,
                    survey: surveys,
                    filterOptions: location.state?.filterOptions
                },
            });
        } catch (e) {
            console.log("errored");
            console.log(e);
        }
    };

    const downloadPdf = async (certificate) => {
        try {
            const response = await axios.get(env.backend + '/api/surveyor/finalizedpdfpreview', {
                params: {
                    certificateName: certificate.certificateName.replace(".html", ".pdf"),
                    companyName: companyName,
                    surveyId: surveyId,
                    surveyName: surveyName,
                    vesselname: vesselName,
                    vesselid: vesselId,
                    flow: flow
                },
                responseType: 'blob',
                headers: {
                    'Accept': 'application/pdf'
                }
            });

            if (response.status === 200) {
                console.log("status ok")
                console.log(response)
                const blob = response.data;
                const url = URL.createObjectURL(blob);
                console.log(url)

                // Create a temporary anchor element for downloading
                let fileName = certificate.certificateName;

                // If the file has a .html or .pdf extension, replace it with the appropriate format
                if (fileName.endsWith(".html")) {
                    fileName = fileName.replace(".html", `_${surveys?.vesselName}.pdf`);
                } else if (fileName.endsWith(".pdf")) {
                    fileName = fileName.replace(".pdf", `_${surveys?.vesselName}.pdf`);
                } else {
                    // If there's no .html or .pdf extension, just append the vessel name with .pdf
                    fileName = `${fileName}_${surveys?.vesselName}.pdf`;
                }
                const downloadLink = document.createElement('a');
                downloadLink.href = url;
                downloadLink.setAttribute('download', fileName);
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            } else {
                // Handle the error response
                console.log("error")
            }
        } catch (error) {
            console.error("Error: ", error);
        }
    };
    const selectAllCertificates = () => {
        if (selectedCertificates.length === filteredItems.filter(certificate => certificate.surveyCertificateStatus === "Approved").length) {
            // If all approved certificates are currently selected, unselect all
            setSelectedCertificates([]);
        } else {
            // Otherwise, select all approved certificates
            const approvedCertificates = filteredItems
                .filter(certificate => certificate.surveyCertificateStatus === "Approved")
                .map(certificate => certificate.surveyCertificateId);

            setSelectedCertificates(approvedCertificates);
        }
    };


    const toggleCertificateSelection = (certificateId) => {
        if (selectedCertificates.includes(certificateId)) {
            setSelectedCertificates(selectedCertificates.filter(id => id !== certificateId));
        } else {
            setSelectedCertificates([...selectedCertificates, certificateId]);
        }
    };

    const downloadSelectedCertificates = async () => {
        try {
            for (const certificateId of selectedCertificates) {
                const certificate = filteredItems.find(item => item.surveyCertificateId === certificateId);
                if (certificate) {
                    await downloadPdf(certificate);
                }
            }
            console.log("All certificates downloaded successfully.");
        } catch (error) {
            console.error("Error downloading selected certificates: ", error);
        }
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    useEffect(() => {
        if (searchTerm) {
            const searchFilteredSurveys = surveys?.surveyCertificateList.filter((survey) =>
                survey.certificateName.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredSurveys(searchFilteredSurveys);
        } else {
            setFilteredSurveys(surveys?.surveyCertificateList);
        }
    }, [searchTerm]);

    return (
        <div>
            <header>
                <Header />
            </header>
            <div className="container-fluid">
                <Tabs className="vertical-tabs" style={{ height: 'fit-content' }}>
                    <TabList className="hidden-tab-list" >
                        <Tab className="hidden-tab-list"></Tab>
                    </TabList>
                    <LeftNav />
                    <TabPanel style={{ width: "87%" }}>
                        <main>
                            <div className="container-fluid-buyer">
                                <div class="card-listing-section">
                                    {/* <h2 class="active-menu ml-3">Listings</h2> */}
                                    <div class="right-wrapper " style={{ minHeight: '50vh' }}>
                                        <div >

                                            <div className="header-container">
                                                <h1><div className="header-surveyor" style={{ textAlign: 'left', marginLeft: '0px', marginTop: '-7px', fontSize: '26px' }}>
                                                    {status === 'Approved' ? 'Certificates' : 'Templates'}</div>
                                                </h1>


                                            </div>
                                            <div class="active-heading" style={{ marginTop: "-23px" }} >
                                                Survey Name : {surveys?.surveyName || 'NA'}</div>
                                            <div style={{ marginBottom: "21px" }}>
                                                <FormControl fullWidth>
                                                    {/* <InputLabel>Search by Certificate Name</InputLabel> */}
                                                    <TextField
                                                        label="Search by Certificate Name"
                                                        variant="outlined"
                                                        fullWidth
                                                        margin="normal"
                                                        value={searchTerm}
                                                        onChange={handleSearchChange}
                                                        sx={{
                                                            marginBottom: "0 !important",
                                                            marginTop: "0 !important", '& .MuiInputLabel-root': {
                                                                marginTop: '-11px',
                                                                fontFamily: 'Segoe UI Historic',
                                                            },
                                                            '& .MuiInputLabel-shrink': {
                                                                marginTop: '0px',
                                                            },
                                                            '& .MuiOutlinedInput-input': {
                                                                height: "25px",
                                                                margin: '4px',
                                                                fontFamily: 'Segoe UI Historic',
                                                            }
                                                        }}
                                                    />
                                                </FormControl>
                                            </div>
                                            {
                                                loading ? (<LoaderDashboard />) : (

                                                    <div >
                                                        <div>
                                                            <TableContainer component={Paper} sx={{ maxHeight: '72vh', marginTop: '-19px' }}>
                                                                <Table stickyHeader aria-label="sticky table">
                                                                    <TableHead >

                                                                        <TableRow>
                                                                            {(flow === "Approval") &&

                                                                                <TableCell className='table-head-new' >
                                                                                    <input style={{ marginLeft: '10px' }}
                                                                                        type="checkbox"
                                                                                        checked={selectedCertificates?.length === filteredItems?.filter(certificate => certificate.surveyCertificateStatus === "Approved").length}
                                                                                        onChange={selectAllCertificates}
                                                                                    />
                                                                                    Select

                                                                                </TableCell>
                                                                            }
                                                                            <TableCell className='table-head-new' >Company</TableCell>
                                                                            <TableCell className='table-head-new' >Vessel Name</TableCell>
                                                                            {/* <TableCell className='table-head-new' >Id</TableCell> */}
                                                                            <TableCell className='table-head-new' >Template Name</TableCell>
                                                                            <TableCell className='table-head-new' >Updated By</TableCell>
                                                                            <TableCell className='table-head-new' >Status</TableCell>
                                                                            <TableCell className='table-head-new' >Issue Date</TableCell>
                                                                            <TableCell className='table-head-new' >Rejection Reason</TableCell>
                                                                            <TableCell className='table-head-new' >Actions</TableCell>

                                                                        </TableRow>

                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {filteredSurveys?.map((survey, index) => (
                                                                            <TableRow key={index}>
                                                                                {/* {(flow !== "SurveyorCompleted" || ((flow === "Approval") && (survey.surveyCertificateStatus === "Approved"))) && */}
                                                                                <>
                                                                                    {(flow === "Approval") && (
                                                                                        <TableCell className="table-cell-survey">
                                                                                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                                                                {survey.surveyCertificateStatus === "Approved" && (
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        checked={selectedCertificates.includes(survey.surveyCertificateId)}
                                                                                                        onChange={() => toggleCertificateSelection(survey.surveyCertificateId)}
                                                                                                        style={{ marginRight: '10px' }}
                                                                                                    />
                                                                                                )}
                                                                                                {survey.surveyCertificateStatus !== "Approved" && <span style={{ width: '22px' }}></span>}
                                                                                                <span>{index + 1}</span>
                                                                                            </div>
                                                                                        </TableCell>

                                                                                    )}

                                                                                    <TableCell className="table-cell-survey">{surveys.companyName}</TableCell>
                                                                                    <TableCell className="table-cell-survey">{surveys.vesselName}</TableCell>
                                                                                    {/* <TableCell className="table-cell-survey">{survey.surveyCertificateId}</TableCell> */}
                                                                                    <TableCell className="table-cell-survey">{survey.certificateName}</TableCell>
                                                                                    <TableCell className="table-cell-survey">{survey.surveyCertificateStatus === "New" ? survey.createdBy : survey.updatedBy}</TableCell>
                                                                                    <TableCell className="table-cell-survey">{survey.surveyCertificateStatus}</TableCell>
                                                                                    <TableCell className='table-cell-survey'>
                                                                                        {(survey.issuedDate || surveyIssueDate) && !isNaN(new Date(survey.issuedDate || surveyIssueDate).getTime())
                                                                                            ? new Intl.DateTimeFormat('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })
                                                                                                .format(new Date(survey.issuedDate || surveyIssueDate))
                                                                                            : "NA"}
                                                                                    </TableCell>




                                                                                    <TableCell className="table-cell-survey">{survey.comment}</TableCell>
                                                                                    <TableCell className="table-cell-survey">
                                                                                        <div style={{ display: "flex", width: '40%' }}>
                                                                                            {flow !== "Approval" && flow !== "SurveyorCompleted" && survey.surveyCertificateStatus !== "Completed" &&
                                                                                                <Tooltip title="Edit">
                                                                                                    <IconButton sx={{ padding: '2px' }} onClick={() => handleButtonClick(survey)}>
                                                                                                        <EditIcon />
                                                                                                    </IconButton>
                                                                                                </Tooltip>
                                                                                            }
                                                                                            {(survey.surveyCertificateStatus === "Completed" || flow === "Approval" || flow === "SurveyorCompleted") &&
                                                                                                <Tooltip title="PDF">
                                                                                                    <IconButton sx={{ padding: '2px' }} onClick={() => handlePDFPreview(survey)}>
                                                                                                        <PictureAsPdfIcon />
                                                                                                    </IconButton>
                                                                                                </Tooltip>
                                                                                            }
                                                                                            {(survey.surveyCertificateStatus === "Approved") &&
                                                                                                <Tooltip title="Download PDF">
                                                                                                    <IconButton sx={{ padding: '2px' }} onClick={() => downloadPdf(survey)}>
                                                                                                        <SimCardDownloadIcon />
                                                                                                    </IconButton>
                                                                                                </Tooltip>
                                                                                            }
                                                                                        </div>
                                                                                    </TableCell>
                                                                                </>

                                                                            </TableRow>
                                                                        ))}
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </div>

                                                    </div>

                                                )}


                                        </div>
                                        <div class="btn-container-multiple" style={{ marginTop: "10px" }}>
                                            <button
                                                class="modal-close-btn" onClick={handleClose}>
                                                Close
                                            </button>
                                            {(flow === "Approval") &&
                                                <button class="modal-preview-btn" onClick={downloadSelectedCertificates}>Download</button>
                                            }

                                        </div>
                                    </div>

                                </div>

                            </div>

                        </main>
                    </TabPanel>
                </Tabs>
            </div>
        </div>

    );
}

export default ViewCertificates;