import * as React from 'react';
import ENV from "../../config";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';
//import { DecoupledEditor } from '@ckeditor/ckeditor5-build-decoupled-document';
//import LineHeight from 'ckeditor5-line-height';
import '../../Css/customckeditor.css';
//import '../../Css/doceditor.css';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import CircularLoader from '../../Components/CircularLoader';
import handleToken from '../../Components/TokenHandler';
import Header from '../../Header/Header';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Loader from '../../Components/CircularLoader';



const { version, useReducer } = React;
/* Liz Added */
const { useEffect, useState } = React;

const USER_SERVICE_URL = ENV.backend + '/api/html-string';

const CONVERTER_SERVICE_URL = ENV.backend + '/api/html-to-pdf';
const styles = "<style> @page {size: A4;margin: 0.5cm 1cm 0.7cm 1cm;@bottom-center {content:'Page ' counter(page) ' of ' counter(pages);font-size: 8pt; margin-top:0.2cm;} @bottom-left {content:'{{TEMPLATENAME}}';font-size: 8pt; margin-top:0.2cm;}}.todo-list__label__description {position: relative;top: -2px;} ::marker{font-size: xx-small;}  body p span {margin-bottom: 0px !important;margin-top: 0px !important; line-height: 1.25;}body p {margin-bottom: 0px !important;margin-top: 0px !important;line-height: 1; font-size:12px;}    .todo-list {list-style: none; margin:0;}.todo-list .todo-list__label > input {margin-top:8px;}.table .ck-table-resized { table-layout: fixed; } .table table { overflow: hidden; } .table td, .table th { overflow-wrap: break-word; position: relative; padding: 0 !important; font-size: 12px; font-family: 'Times New Roman', Times, serif;} .table > figcaption { display: table-caption; caption-side: top; word-break: break-word; text-align: center; padding: .6em; font-size: .75em; outline-offset: -1px; } .table { margin: 0 auto; display: table; } .table table { border-collapse: collapse; border-spacing: 0; width: 100%; height: 100%; } .table table td, .table table th { min-width: 2em; padding: 0 !important; height:15px; padding-left: 2px !important;} .table table th { font-weight: bold; } .table th { text-align: right; } .table th { text-align: left; }.table table tr {page-break-inside: avoid;  page-break-before: auto; page-break-after: auto;}.table tr {page-break-inside: avoid;  page-break-before: auto; page-break-after: auto;}</style>"
//const top = "<?xml version=\"1.0\" encoding=\"utf-8\"?><!DOCTYPE html PUBLIC \"-//W3C//DTD XHTML 1.1//EN\" \"http://www.w3.org/TR/xhtml11/DTD/xhtml11.dtd\"><html xmlns=\"http://www.w3.org/1999/xhtml\"><head><meta http-equiv=\"Content-Type\" content=\"application/xhtml+xml; charset=utf-8\" /><title>THE GOVERNMENT OF BELIZE</title><link href=\"UserDefinedFileName.css\" type=\"text/css\" rel=\"stylesheet\"/></head><body>";
const top = '<?xml version="1.0" encoding="utf-8"?><!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.1//EN" "http://www.w3.org/TR/xhtml11/DTD/xhtml11.dtd"><html xmlns="http://www.w3.org/1999/xhtml"><head><meta http-equiv="Content-Type" content="application/xhtml+xml; charset=utf-8" /><title>NEOCAP TEMPLATES</title></head>'
const body = "<body>"
const bottom = "</body></html>"


/**
 * `App` component manages state of underlying `CKEditor` and `Sidebar` components.
 *
 * Custom `CKEditor` component memoizes certain props and it will ignore any new values. For instance, this is true for `config` and `type`.
 * In order to force new `config` or `type` values, use keyed component.
 * This way `CKEditor` component is re-mounted and new instance of editor is created.
 */
function CertEditor() {
	// State variables for managing loading states, user data, certificate data, and editor instance
	const [loading, setLoading] = React.useState(false);
	const [userdata, setUserData] = useState({ users: "", isFetching: false });
	const [certdata, setCertData] = useState('');
	const [isLoading, setIsLoading] = useState(true);

	// Access location and navigate for routing
	const [editor1, setEditor] = useState();
	const location = useLocation();
	const navigate = useNavigate();
	const text = location.state.data ?? '';
	const company = location.state.company ?? '';
	const flow = location.state.flow ?? '';
	// console.log('location.state:', location.state);
	// console.log('text:', text);
	// console.log('company:', company);
	// console.log('flow:', flow);

	// State to manage the visibility of messages
	const [showMessage, setShowMessage] = useState(false);

	// Define navigation paths
	const totemplatelist = location.state?.from?.pathname || "/list-templates"
	const topdfpreviewer = "/pdf-previewer";
	const setError = () => { };
	const errorpage = "/error";


	useEffect(() => {
		// handleToken();
	})

	useEffect(() => {
		setShowMessage(false);
		// setIsLoading(true);


		//console.log(editor)
		//if ( editor && status === 'ready' ) {
		// console.log("inside")
		const fetchUsers = async () => {
			try {
				// console.log("inside sync")

				// Start fetching process
				setUserData((userdata) => ({ users: userdata.users, isFetching: false }));

				// Fetch certificate data from server
				const response = await axios.get(USER_SERVICE_URL, {
					params: {
						templateName: text,
						companyName: company,

					}
				})
				// Check if response is valid
				if (response !== null) {
					setIsLoading(false);

				}
				// console.log("inside sync" + response.data);
				let updatedHtmlData = increaseFontSizeOutsideStyle(response.data);


				//const base64 = require('base64topdf')
				//const decodedresponse = base64.decode(response.data)
				setUserData({ users: updatedHtmlData, isFetching: false });
				//console.log(decodedresponse);
				// console.log('after set');
				//console.log(editor.getData());
				//editor.setData(updatedHtmlData)
				setCertData(updatedHtmlData);

				// console.log(certdata);

			} catch (e) {
				console.log(" errored")
				console.log(e);
				setIsLoading(false);
				setUserData((userdata) => ({ users: userdata.users, isFetching: false }));
			}
		};
		fetchUsers();
	}, [certdata, company, text]);

	// Function to increase font sizes outside of style tags
	function increaseFontSizeOutsideStyle(htmlString) {
		// Split the HTML content into two parts: before </style> and after </style>
		const parts = htmlString.split('</style>');
		const beforeStyle = parts[0];
		const afterStyle = parts[1];

		// Increase font sizes by 1 in the afterStyle part
		const updatedAfterStyle = afterStyle.replace(/font-size:(\d+)px;/g, (match, p1) => {
			let newSize = parseInt(p1) + 1;
			return `font-size:${newSize}px;`;
		});

		// Combine the parts back together
		return beforeStyle + '</style>' + updatedAfterStyle;
	}

	// Function to handle PDF preview
	const handlePreviewPDF = () => {

		handleChange(); // Ensure data is processed before preview
		setTimeout(() => {
			navigateToPDFPreview(); // Navigate to PDF previewer after delay
		}, 2000); // Delay of 2 seconds


		function navigateToPDFPreview() {
			try {
				// Construct PDF filename from HTML template
				let temp = location.state.data;
				const pdfContent = temp.replace(/\.html$/, '.pdf');
				// console.log("pdf content", pdfContent);

				navigate(topdfpreviewer, {
					state: {
						filename: pdfContent,
						company: company,
						fromPreview: true,
						flow: "ckeditor"
					},
				});
			} catch (e) {
				// Log error and navigate to error page
				console.log("Errored while navigating to PDF preview:", e);
				setError("errored");
				navigate(errorpage, { replace: true });
			}
		}

	};

	// Function to handle closing the editor and navigating to template list
	const handleClose = evt => {
		navigate(totemplatelist);
	}
	// Function to decrease font sizes in HTML string
	function decreaseFontSize(htmlString) {
		// Use a regular expression to find all font-size values in the HTML string
		return htmlString.replace(/font-size:(\d+)px;/g, (match, p1) => {
			// Convert the found size to an integer, decrease it by 1, and return the new size
			let newSize = parseInt(p1) - 1;
			return `font-size:${newSize}px;`;
		});
	}

	// Function to remove specific tags and add spaces in HTML string
	function removeTagsAndAddSpace(htmlString) {
		// Regular expression to match <o:wrapblock>...</o:wrapblock>
		const wrapblockRegex = /<o:wrapblock>.*?<\/o:wrapblock>/gs;
		// Regular expression to match <o:p></o:p>
		const pTagRegex = /<o:p><\/o:p>/g;

		// Replace <o:wrapblock>...</o:wrapblock> with a space
		let result = htmlString.replace(wrapblockRegex, ' ');
		// Replace <o:p></o:p> with a space
		result = result.replace(pTagRegex, ' ');

		return result;
	}

	function replaceFigureWithP(htmlString) {
		// Create a DOM parser to parse the HTML string
		const parser = new DOMParser();
		const doc = parser.parseFromString(htmlString, 'text/html');

		// Get all <figure> elements without an id
		const figures = doc.querySelectorAll('figure:not([id])');

		figures.forEach((figure) => {
			const className = figure.className || '';
			const img = figure.querySelector('img');

			// Check the figure's className
			if (className === 'image image_resized' || className === 'image image_resized image-style-side') {
				// Create a new <p> element
				const p = document.createElement('p');
				p.className = 'image-container';

				// Add alignment styles based on the class name
				if (className === 'image image_resized') {
					p.style.textAlign = 'center'; // Center align
				} else if (className === 'image image_resized image-style-side') {
					p.style.textAlign = 'right'; // Right align
				}

				// Transfer the figure's style to the image
				if (figure.hasAttribute('style')) {
					img.setAttribute('style', figure.getAttribute('style'));
				}

				// Ensure the image has display:inline-block for alignment
				img.style.display = 'inline-block';

				// Move the <img> from the <figure> to the <p>
				p.appendChild(img);

				// Replace the <figure> with the <p>
				figure.replaceWith(p);
			}
		});

		// Return the modified HTML string
		return doc.body.innerHTML;
	}


	// Function to handle changes and submit data
	const handleChange = evt => {
		setLoading(true);

		// Process HTML data
		let updatedHtmlData = decreaseFontSize(editor1.getData());
		let tagRemovedData = removeTagsAndAddSpace(updatedHtmlData);
		let figureChangedtoP = replaceFigureWithP(tagRemovedData);

		const value1 = ((top + styles + body + figureChangedtoP + bottom));
		// const customizedData = customizeData(value1);
		// console.log("Get data of ckeditor", editor1.getData());
		// console.log("Beofre customise", value1);
		const value = ((value1));
		// console.log("Value after customise ", value);
		// console.log (editor1.getData('docx'));
		//dispatch( { type: 'submit', payload: data } );
		//const response = "";
		//console.log(value)
		// console.log(text)
		// console.log(company)
		//const response =  axios.post(CONVERTER_SERVICE_URL,value);
		const data = {
			companyName: company,
			templateName: text,
			htmlString: value,
			flow: flow
		};
		const response = axios.post(CONVERTER_SERVICE_URL, data, {
			headers: {
				// 'Content-Type': 'text/html',
			}
		})
			.then(response => {
				// Display message after receiving the response
				setShowMessage(true);
				console.log(response);
				setLoading(false);
				setTimeout(() => {
					setShowMessage(false);
				}, 2000);
			})
			.catch(error => {
				// Handle errors if needed
				console.error('Error:', error);
				setLoading(false);
			});


		// console.log("inside sync" + response);
	};





	return (
		<div>
			<header>
				<Header />
			</header>
			<div className="container-fluid container-fluid-ex">
				<Tabs className="vertical-tabs" style={{ maxHeight: '90vh' }}>
					<TabList className="hidden-tab-list" >
						<Tab className="hidden-tab-list"></Tab>
					</TabList>
					{/* <LeftNav /> */}
					<TabPanel style={{ width: "96%", marginRight: "2%", marginLeft: "2%" }}>
						<main style={{ maxHeight: "90vh" }}>
							<div>
								<div class="active-heading" >
									Template Name : {text || 'Editor'}</div>
							</div>
							{isLoading ? (<Loader />) : (
								<div class="new-editor-wrapper" >
									<div >
										{/* <h2 class="active-menu ml-3">Listings</h2> */}
										<div className="error-message"><b>To prevent data loss, make sure to click 'Save' button in every 5 minutes.</b></div>
										<div >
											<CKEditor
												editor={ClassicEditor}
												id="ckeditor1"
												data={certdata}
												onReady={editor => {
													// You can store the "editor" and use when it is needed.
													setEditor(editor);
													// console.log('Editor is ready to use!');
												}}
												config={{
													watchdog: false,
													title: false,
													heading_styles: {
														'font-size': '8px',
													},
													toolbar:

													{

														// shouldNotGroupWhenFull: true
													},
													htmlSupport: {
														allow: [
															{
																name: /.*/,
																attributes: true,
																classes: true,
																styles: false,
																title: false
															}
														],
													},
													fontFamily: {
														options: [
															'default',
															'Times New Roman, Times, serif',
															'Book Antiqua, Antiqua serif',
															'Arial, Helvetica, sans-serif',
															'Courier New, Courier, monospace',
															'Georgia, serif',
															'Lucida Sans Unicode, Lucida Grande, sans-serif',
															'Tahoma, Geneva, sans-serif',
															'Trebuchet MS, Helvetica, sans-serif',
															'Verdana, Geneva, sans-serif'

														],
														supportAllValues: true

													},
													fontSize: {
														options: [
															'default',
															9,
															10,
															11,
															12,
															13,
															14,
															15,
															16,
															17,
															18,
															19,
															20,
															21
														]
													},

												}}


											/>
										</div>

										{showMessage && <div><b className="success-message">Template is created! You can close the editor safely!</b></div>}

									</div>
									{loading ? <CircularLoader /> : (
										<>
											{!showMessage && <div >
												<div class="btn-container-multiple" style={{ marginTop: "10px" }}>
													<button style={{ height: '28.5px' }}
														class="modal-close-btn" onClick={handleClose}><div style={{ marginTop: '-7.5px' }}><b>Close</b></div></button>
													{flow !== "create" &&
														<button style={{ height: '28.5px' }}
															class="modal-preview-btn" onClick={handlePreviewPDF}><div style={{ marginTop: '-7.5px' }}><b>Preview PDF</b></div></button>}

													<button style={{ height: '28.5px' }}
														class="modal-submit-btn"
														id="submit"
														type="button"
														value="Submit"
														onClick={handleChange}

													><div style={{ marginTop: '-7.5px' }}><b>Save</b></div></button>
												</div>
											</div>}
										</>
									)}
								</div>
							)}


						</main>
					</TabPanel>
				</Tabs>
			</div>
		</div >
	);
}





export default CertEditor;