import * as React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom'
import ENV from "../config";
import Modal from 'react-modal';
import { useContext } from "react";
import AuthContext from "../Context/authProvider";
import '../Css/customckeditor.css';
import dayjs from 'dayjs';


import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import CircularLoader from '../Components/CircularLoader';
import handleToken from '../Components/TokenHandler';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField } from '@mui/material';
import Header from '../Header/Header';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Loader from '../Components/CircularLoader';
import zIndex from '@mui/material/styles/zIndex';



const { version, useReducer } = React;
/* Liz Added */
const { useEffect, useState } = React;


//const USER_SERVICE_URL = 'http://44.207.146.240/api/surveyor/html-string';
const GET_HTML_SERVICE_URL = ENV.backend + '/api/surveyor/html-string';


const SAVE_SERVICE_URL = ENV.backend + '/api/surveyor/html-to-pdf-save';

const COMPLETION_SERVICE_URL = ENV.backend + '/api/surveyor/html-to-pdf-finalize';


const styles = "<style> @page {size: A4;margin: 0.5cm 1cm 0.7cm 1cm;@bottom-center {content:'Page ' counter(page) ' of ' counter(pages);font-size: 8pt; margin-top:0.2cm;} @bottom-left {content:'{{TEMPLATENAME}}';font-size: 8pt; margin-top:0.2cm;}}.todo-list__label__description {position: relative;top: -2px;} ::marker{font-size: xx-small;}  body p span {margin-bottom: 0px !important;margin-top: 0px !important; line-height: 1.25;}body p {margin-bottom: 0px !important;margin-top: 0px !important;line-height: 1; font-size:12px;}    .todo-list {list-style: none; margin:0;}.todo-list .todo-list__label > input {margin-top:8px;}.table .ck-table-resized { table-layout: fixed; } .table table { overflow: hidden; } .table td, .table th { overflow-wrap: break-word; position: relative; padding: 0 !important; font-size: 12px; font-family: 'Times New Roman', Times, serif;} .table > figcaption { display: table-caption; caption-side: top; word-break: break-word; text-align: center; padding: .6em; font-size: .75em; outline-offset: -1px; } .table { margin: 0 auto; display: table; } .table table { border-collapse: collapse; border-spacing: 0; width: 100%; height: 100%; } .table table td, .table table th { min-width: 2em; padding: 0 !important; height:15px; padding-left: 2px !important;} .table table th { font-weight: bold; } .table th { text-align: right; } .table th { text-align: left; }.table table tr {page-break-inside: avoid;  page-break-before: auto; page-break-after: auto;}.table tr {page-break-inside: avoid;  page-break-before: auto; page-break-after: auto;}</style>"
//const top = "<?xml version=\"1.0\" encoding=\"utf-8\"?><!DOCTYPE html PUBLIC \"-//W3C//DTD XHTML 1.1//EN\" \"http://www.w3.org/TR/xhtml11/DTD/xhtml11.dtd\"><html xmlns=\"http://www.w3.org/1999/xhtml\"><head><meta http-equiv=\"Content-Type\" content=\"application/xhtml+xml; charset=utf-8\" /><title>THE GOVERNMENT OF BELIZE</title><link href=\"UserDefinedFileName.css\" type=\"text/css\" rel=\"stylesheet\"/></head><body>";
const top = '<?xml version="1.0" encoding="utf-8"?><!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.1//EN" "http://www.w3.org/TR/xhtml11/DTD/xhtml11.dtd"><html xmlns="http://www.w3.org/1999/xhtml"><head><meta http-equiv="Content-Type" content="application/xhtml+xml; charset=utf-8" /><title>NEOCAP TEMPLATES</title></head>'
const body = "<body>"
const bottom = "</body></html>"

/**
 * `App` component manages state of underlying `CKEditor` and `Sidebar` components.
 *
 * Custom `CKEditor` component memoizes certain props and it will ignore any new values. For instance, this is true for `config` and `type`.
 * In order to force new `config` or `type` values, use keyed component.
 * This way `CKEditor` component is re-mounted and new instance of editor is created.
 */
function CertEditorSurveyor() {
	// Extract authentication context
	const { auth } = useContext(AuthContext);


	// State variables for managing editor data, loading states, and modal visibility
	const [userdata, setUserData] = useState({ users: "", isFetching: false });
	const [editor1, setEditor] = useState();
	const location = useLocation();
	console.log(location.state);

	const navigate = useNavigate();
	const [loading, setLoading] = React.useState(false);
	const [showSurveyCertificates, setShowSurveyCertficates] = useState(false);
	const surveycertificatename = location.state.surveycertificatename ?? '';
	const company = location.state.company ?? '';
	const surveyname = location.state.surveyname ?? '';
	const surveyid = location.state.surveyid ?? '';
	const vesselname = location.state.vesselname ?? '';
	const vesselid = location.state.vesselid ?? '';
	const surveycertificateid = location.state.surveycertificateid ?? '';
	const flagpath = location.state.flagpath ?? '';
	const completionDate = location.state.completionDate ?? '';
	const issuePlace = location.state.issuePlace ?? '';
	const comment = location.state?.comment ?? "";


	const surveys = location.state?.survey;

	const flow = location.state?.flow;
	const [status, setStatus] = useState(location.state.status);

	const [isLoading, setIsLoading] = useState(true);
	const [issuedDate, setIssuedDate] = useState(status === "Rejected" ? location.state.certIssuedDate : location.state.certIssuedDate || location.state.issueDate);
	const [expiryDate, setExpiryDate] = useState(status === "Rejected" ? location.state.certExpiryDate : location.state.certExpiryDate || location.state.validUntilDate);
	const [certificateGoodName, setCertificateGoodName] = useState('');
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [showEditor, setShowEditor] = useState(true);
	const [showComplete, setShowComplete] = useState(false);
	const [issuedPlace, setIssuedPlace] = useState(issuePlace || '');
	const [certificateType, setCertificateType] = useState('');
	//const surveycertificatename = location.state.surveycertificatename ?? '';


	const [showMessage, setShowMessage] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	const totemplatelist = location.state?.from?.pathname || "/surveyor-dashboard"
	const topdfpreviewer = "/saved-pdf-previewer";
	const topdfFinalizedpreviewer = "/finalized-pdf-previewer";

	useEffect(() => {
		// handleToken();

	})

	// Effect to fetch data and update state when component mounts
	useEffect(() => {
		setShowMessage(false);


		//console.log(editor)
		//if ( editor && status === 'ready' ) {
		// console.log("inside")
		const fetchUsers = async () => {
			try {
				// console.log("inside sync")
				setUserData((userdata) => ({ users: userdata.users, isFetching: false }));
				// console.log(flagpath)

				// Fetch certificate data from server
				const response = await axios.get(GET_HTML_SERVICE_URL, {
					params: {
						templateName: surveycertificatename,
						companyName: company,
						surveyName: surveyname,
						vesselName: vesselname,
						vesselId: vesselid,
						surveyId: surveyid,
						flagPath: flagpath

					}
				})
				// Update state with fetched data
				if (response !== null) {
					setIsLoading(false);

				}

				// console.log("inside sync" + response.data);

				let updatedHtmlData = increaseFontSizeOutsideStyle(response.data);

				//const base64 = require('base64topdf')
				//const decodedresponse = base64.decode(response.data)
				setUserData({ users: updatedHtmlData, isFetching: false });
				//console.log(decodedresponse);
				// console.log('after set');
				//console.log(editor.getData());
				//editor.setData(response.data)

			} catch (e) {
				console.log(" errored")
				console.log(e);
				setIsLoading(false);
				setUserData((userdata) => ({ users: userdata.users, isFetching: false }));
			}
		};
		fetchUsers();
	}, [status]);

	// Function to handle PDF preview
	const handlePreviewPDF = () => {

		handleSave(); // Ensure data is saved before previewing
		setTimeout(() => {
			proceedWithPreview(); // Proceed with PDF preview after a delay
		}, 2000); // 1000 milliseconds = 1 second

	};

	// Function to navigate to PDF preview page
	const proceedWithPreview = () => {
		let certificateName = location.state.surveycertificatename;

		if (certificateName && certificateName !== null) {
			certificateName = certificateName.replace(".html", ".pdf");
		}

		// console.log("calling");

		try {
			// Navigate to PDF previewer with required state
			navigate(topdfpreviewer, {
				state: {
					surveycertificateid: location.state?.surveycertificateid,
					surveycertificatename: certificateName,
					company: location.state?.company,
					surveyname: location.state?.surveyname,
					countryName: location.state?.countryName,
					surveyid: location.state?.surveyid,
					vesselname: location.state?.vesselname,
					vesselid: location.state?.vesselid,
					flagpath: location.state?.flagpath,
					status: status,
					completionDate: location.state?.completionDate,
					validUntilDate: location.state?.validUntilDate,
					issuePlace: location.state?.issuePlace,
					issueDate: location.state?.issueDate,
					certIssuedDate: location.state?.certIssuedDate,
					certExpiryDate: location.state?.certExpiryDate,
					survey: location.state?.survey,
					flow: "",
					prevFlow: flow,
					filterOptions: location.state?.filterOptions,
					comment: comment

				},
			});
		} catch (e) {
			// Log errors and handle navigation failures
			console.log("errored");
			console.log(e);
		}
	};

	const proceedWithCompletePreview = () => {
		let certificateName = location.state.surveycertificatename;

		if (certificateName && certificateName !== null) {
			certificateName = certificateName.replace(".html", ".pdf");
		}

		// console.log("calling");

		try {
			// Navigate to PDF previewer with required state
			navigate(topdfFinalizedpreviewer, {
				state: {
					surveycertificateid: location.state?.surveycertificateid,
					surveycertificatename: certificateName,
					company: location.state?.company,
					surveyname: location.state?.surveyname,
					countryName: location.state?.countryName,
					surveyid: location.state?.surveyid,
					vesselname: location.state?.vesselname,
					vesselid: location.state?.vesselid,
					flagpath: location.state?.flagpath,
					status: status,
					completionDate: location.state?.completionDate,
					validUntilDate: location.state?.validUntilDate,
					issuePlace: location.state?.issuePlace,
					issueDate: location.state?.issueDate,
					certIssuedDate: location.state?.certIssuedDate,
					certExpiryDate: location.state?.certExpiryDate,
					survey: location.state?.survey,
					flow: "no-back",
					prevFlow: flow,
					filterOptions: location.state?.filterOptions,
					comment: comment

				},
			});
		} catch (e) {
			// Log errors and handle navigation failures
			console.log("errored");
			console.log(e);
		}
	};
	// Function to handle navigation back to the view certificates page	
	const handleBack = () => {
		navigate('/surveyorViewCertificates', {
			state: {
				status: status,
				survey: surveys,
				flow: flow,
				filterOptions: location.state?.filterOptions
			},
		});
	};

	// Function to open modal for additional actions
	const openModal = () => {
		setModalIsOpen(true);
	};

	// Function to close modal and reset certain states
	const closeModal = () => {
		setShowEditor(true);
		setModalIsOpen(false);
		setShowMessage(false);
	};


	//   const handleClickSurveyItem = () => {
	// 	if (!showSurveyCertificates){
	// 	setShowSurveyCertficates(true)

	// 	setTimeout(() => {
	// 	  // Code to be executed after the delay
	// 	}, 700);

	// 	}

	// 	else
	// 	setShowSurveyCertficates(false)

	// 	};

	const handleClose = evt => {
		navigate('/surveyorViewCertificates', {
			state: {
				status: status,
				survey: surveys,
				flow: flow,
				surveyId: surveyid,
				filterOptions: location.state?.filterOptions
			},
		});
	}
	// Function to increase font sizes outside of style tags
	function increaseFontSizeOutsideStyle(htmlString) {
		// Split the HTML content into two parts: before </style> and after </style>
		const parts = htmlString.split('</style>');
		const beforeStyle = parts[0];
		const afterStyle = parts[1];

		// Increase font sizes by 1 in the afterStyle part
		const updatedAfterStyle = afterStyle.replace(/font-size:(\d+)px;/g, (match, p1) => {
			let newSize = parseInt(p1) + 1;
			return `font-size:${newSize}px;`;
		});

		// Combine the parts back together
		return beforeStyle + '</style>' + updatedAfterStyle;
	}
	// Function to decrease font sizes in HTML string
	function decreaseFontSize(htmlString) {
		// Use a regular expression to find all font-size values in the HTML string
		return htmlString.replace(/font-size:(\d+)px;/g, (match, p1) => {
			// Convert the found size to an integer, decrease it by 1, and return the new size
			let newSize = parseInt(p1) - 1;
			return `font-size:${newSize}px;`;
		});
	}
	function removeTagsAndAddSpace(htmlString) {
		// Regular expression to match <o:wrapblock>...</o:wrapblock>
		const wrapblockRegex = /<o:wrapblock>.*?<\/o:wrapblock>/gs;
		// Regular expression to match <o:p></o:p>
		const pTagRegex = /<o:p><\/o:p>/g;

		// Replace <o:wrapblock>...</o:wrapblock> with a space
		let result = htmlString.replace(wrapblockRegex, ' ');
		// Replace <o:p></o:p> with a space
		result = result.replace(pTagRegex, ' ');

		return result;
	}
	function replaceFigureWithP(htmlString) {
		// Create a DOM parser to parse the HTML string
		const parser = new DOMParser();
		const doc = parser.parseFromString(htmlString, 'text/html');

		// Get all <figure> elements without an id
		const figures = doc.querySelectorAll('figure:not([id])');

		figures.forEach((figure) => {
			const className = figure.className || '';
			const img = figure.querySelector('img');

			// Check the figure's className
			if (className === 'image image_resized' || className === 'image image_resized image-style-side') {
				// Create a new <p> element
				const p = document.createElement('p');
				p.className = 'image-container';

				// Add alignment styles based on the class name
				if (className === 'image image_resized') {
					p.style.textAlign = 'center'; // Center align
				} else if (className === 'image image_resized image-style-side') {
					p.style.textAlign = 'right'; // Right align
				}

				// Transfer the figure's style to the image
				if (figure.hasAttribute('style')) {
					img.setAttribute('style', figure.getAttribute('style'));
				}

				// Ensure the image has display:inline-block for alignment
				img.style.display = 'inline-block';

				// Move the <img> from the <figure> to the <p>
				p.appendChild(img);

				// Replace the <figure> with the <p>
				figure.replaceWith(p);
			}
		});

		// Return the modified HTML string
		return doc.body.innerHTML;
	}

	// Function to handle saving data
	const handleSave = evt => {

		setLoading(true);
		const formatDateToString = (date) => {
			// Check if the input date is valid
			if (isNaN(date.getTime())) {
				// If the date is invalid, return an empty string
				return "";
			}

			// Format the valid date
			const yyyy = date.getFullYear();
			const mm = date.toLocaleString('default', { month: 'short' }).toUpperCase();
			const dd = String(date.getDate()).padStart(2, '0');
			const finalDate = `${dd}-${mm}-${yyyy}`;
			if (finalDate === "01-JAN-1970") {
				return "";
			}
			else {
				return finalDate;
			}
		};
		const certExpiryDate = new Date(expiryDate);
		const certIssueDate = new Date(issuedDate);
		// console.log("Saving data");
		// const certExpiryDateString = formatDateToString(certExpiryDate);
		// const certIssueDateString = formatDateToString(certIssueDate);

		// Process HTML data
		let updatedHtmlData = decreaseFontSize(editor1.getData());
		let tagRemovedData = removeTagsAndAddSpace(updatedHtmlData);
		let figureChangedtoP = replaceFigureWithP(tagRemovedData);

		const value1 = ((top + styles + body + figureChangedtoP + bottom));
		const value = ((value1));
		// console.log("Hmtl sending", value);
		// console.log(surveycertificatename);
		// console.log(company);



		// Prepare data for submission
		const actionSubmittedOnString = (new Date());
		let actionSubmittedBy = "";
		if (localStorage.getItem("userFullName") !== "undefined") {
			actionSubmittedBy = localStorage.getItem("userFullName");
		} else {
			actionSubmittedBy = '';
		}
		const data = {
			companyName: company,
			templateName: surveycertificatename,
			htmlString: value,
			surveyCertificateName: surveycertificatename,
			surveyCertificateId: surveycertificateid,
			surveyName: surveyname,
			vesselName: vesselname,
			countryName: location.state?.countryName,
			vesselId: vesselid,
			surveyId: surveyid,
			completionDate: completionDate,
			submittedBy: actionSubmittedBy,
			submittedOn: new Date(),
			updatedBy: actionSubmittedBy,
			updatedOn: new Date(),
			actionSubmittedBy: actionSubmittedBy,
			actionSubmittedOn: new Date(),
			certificateStatus: status === 'Rejected' ? status : 'In-Progress',
			comment: comment
		};

		// Send data to server and handle response
		axios.post(SAVE_SERVICE_URL, data, {
			headers: {
				// 'Content-Type': 'text/html',
			}
		})
			.then(response => {
				// Save operation successful
				console.log("Save successful:", response);

				// Display message after 3 seconds
				setShowMessage(true);
				setLoading(false);
				setTimeout(() => {
					setShowMessage(false);
				}, 2000);

				// Close the template list after saving

				if (status == "New") {
					// handleClose();
					setStatus("In-progress")
					// navigate('/surveyor-dashboard', {
					// 	state: {
					// 		filterOptions: location.state?.filterOptions
					// 	}
					// });
				}

			})
			.catch(error => {
				// Handle error if save operation fails
				console.error("Error saving data:", error);

				// Display error message
				setErrorMessage("Error saving data. Please try again.");
				setTimeout(() => {
					setErrorMessage("");
				}, 5000);
			});
	};
	// const convertToNextDayISO = (dateString) => {
	// 	// Create a Date object from the input date string
	// 	const date = new Date(dateString);

	// 	// Add one day to the date
	// 	date.setDate(date.getDate() + 1);

	// 	// Convert the date back to ISO format
	// 	return date.toISOString();
	// };
	const handleSaveForComplete = evt => {

		setLoading(true);
		// Process HTML data
		let updatedHtmlData = decreaseFontSize(editor1.getData());
		let tagRemovedData = removeTagsAndAddSpace(updatedHtmlData);
		let figureChangedtoP = replaceFigureWithP(tagRemovedData);

		const value1 = ((top + styles + body + figureChangedtoP + bottom)); const value = ((value1));
		// console.log("Hmtl sending", value);
		// console.log(surveycertificatename);
		// console.log(company);



		// Prepare data for submission
		const actionSubmittedOnString = (new Date());
		let actionSubmittedBy = "";
		if (localStorage.getItem("userFullName") !== "undefined") {
			actionSubmittedBy = localStorage.getItem("userFullName");
		} else {
			actionSubmittedBy = '';
		}
		const data = {
			companyName: company,
			templateName: surveycertificatename,
			htmlString: value,
			surveyCertificateName: surveycertificatename,
			surveyCertificateId: surveycertificateid,
			surveyName: surveyname,
			vesselName: vesselname,
			countryName: location.state?.countryName,
			vesselId: vesselid,
			surveyId: surveyid,
			completionDate: completionDate,
			validUntilDate: expiryDate!==null ? new Date(expiryDate):null,
			issuePlace: issuedPlace,
			issueDate:  issuedDate!==null ? new Date(issuedDate):null,
			submittedBy: actionSubmittedBy,
			submittedOn: new Date(),
			updatedBy: actionSubmittedBy,
			updatedOn: new Date(),
			certIssueDate: issuedDate!==null ? new Date(issuedDate):null,
			certExpiryDate: expiryDate!==null ? new Date(expiryDate):null,
			issuedAt: issuedPlace,
			certificateType: certificateType,
			actionSubmittedBy: actionSubmittedBy,
			actionSubmittedOn: new Date(),
			comment: comment,

		};
		console.log("Save for complete  Data", data);

		// Send data to server and handle response
		axios.post(SAVE_SERVICE_URL, data, {
			headers: {
				// 'Content-Type': 'text/html',
			}
		})
			.then(response => {
				// Save operation successful
				console.log("Save successful:", response);
				handleComplete();
				// Display message after 3 seconds
				// Close the template list after saving

				if (status == "New") {
					handleClose();
					// navigate('/surveyor-dashboard', {
					// 	state: {
					// 		filterOptions: location.state?.filterOptions
					// 	}
					// });
				}

			})
			.catch(error => {
				// Handle error if save operation fails
				console.error("Error saving data:", error);

				// Display error message
				setErrorMessage("Error saving data. Please try again.");
				setTimeout(() => {
					setErrorMessage("");
				}, 5000);
			});
	};

	// Function to handle choosing a date and opening modal
	const handleChooseDate = evt => {
		handleSave()
		openModal();
		setUserData({ users: editor1.getData(), isFetching: false });
		// setShowEditor(false)

	};

	// Function to handle completing the survey
	const handleComplete = evt => {
		setLoading(true);
		let updatedHtmlData = decreaseFontSize(userdata?.users);
		let tagRemovedData = removeTagsAndAddSpace(updatedHtmlData);
		let figureChangedtoP = replaceFigureWithP(tagRemovedData);

		const value1 = ((top + styles + body + figureChangedtoP + bottom)); const value = ((value1));
		let actionSubmittedBy = "";
		if (localStorage.getItem("userFullName") !== "undefined") {
			actionSubmittedBy = localStorage.getItem("userFullName");
		} else {
			actionSubmittedBy = '';
		}
		// Prepare data for submission
		const data = {
			companyName: company,
			templateName: surveycertificatename,
			comment: comment,
			htmlString: value,
			surveyName: surveyname,
			surveyId: surveyid,
			vesselName: vesselname,
			vesselId: vesselid,
			countryName: location.state?.countryName,
			surveyCertificateId: surveycertificateid,
			surveyCertificateName: surveycertificatename,
			certificateGoodName: certificateGoodName,
			certIssueDate: issuedDate!==null ? new Date(issuedDate):null,
			certExpiryDate: expiryDate!==null ? new Date(expiryDate):null,
			issuedAt: issuedPlace,
			certificateType: certificateType,
			completionDate: completionDate,
			validUntilDate:expiryDate!==null ? new Date(expiryDate):null,
			issuePlace: issuedPlace,
			issueDate: issuedDate!==null ? new Date(issuedDate):null,
			actionSubmittedBy: actionSubmittedBy,
			actionSubmittedOn: new Date(),
			updatedBy: actionSubmittedBy,
			updatedOn: new Date(),
			submittedBy: actionSubmittedBy,
			submittedOn: new Date(),
		};

		console.log("Complete Data", data);

		// Send data to server and handle response
		const response = axios.post(COMPLETION_SERVICE_URL, data, {
			headers: {
				// 'Content-Type': 'text/html',
			}
		});

		response.then(() => {
			setShowEditor(true);
			setShowMessage(true);


			// Ensure data is saved before previewing
			setTimeout(() => {
				proceedWithCompletePreview(); // Proceed with PDF preview after a delay
			}, 2000); // 1000 milliseconds = 1 second

			// navigate('/surveyor-dashboard', {
			// 	state: {
			// 		filterOptions: location.state?.filterOptions
			// 	}
			// });
			setLoading(false);
			setTimeout(() => {
				setShowMessage(false);
			}, 2000);
		}).catch(error => {
			console.error('Error:', error);
		});
		// console.log("inside sync" + response);
	};

	const [showCompleteMessage, setShowCompleteMessage] = useState(false);
	const [completeMessage, setCompleteMessage] = useState("")

	// Function to handle date selection and closing the modal
	const handleDateSelection = () => {
		// if (certificateGoodName === '' && certificateType === '') {
		// 	setShowCompleteMessage(true);
		// 	setCompleteMessage("Enter Certificate name and select Certificate type.")

		// }
		// else if (certificateGoodName === '') {
		// 	setShowCompleteMessage(true);
		// 	setCompleteMessage("Enter Certificate name.")

		// }
		// else if (certificateType === '') {
		// 	setShowCompleteMessage(true);
		// 	setCompleteMessage("Select Certificate type.")

		// }

		// else {
		setIsLoading(true);
		setShowComplete(true);
		// setShowEditor(true);
		// console.log(certificateGoodName)
		// closeModal();
		handleSaveForComplete();
		// }
	}


	//



	return (
		<div>
			{showEditor && (
				<div>
					<header>
						<Header />
					</header>
					<div className="container-fluid container-fluid-ex">
						<Tabs className="vertical-tabs" style={{ maxHeight: '90vh' }}>
							<TabList className="hidden-tab-list" >
								<Tab className="hidden-tab-list"></Tab>
							</TabList>
							{/* <LeftNav /> */}
							<TabPanel style={{ width: "96%", marginRight: "2%", marginLeft: "2%" }}>
								<main style={{ maxHeight: "90vh" }}>
									<div>
										<div class="active-heading" >
											Template Name : {surveycertificatename || 'Editor'}</div>
									</div>
									{isLoading ? (<Loader />) : (
										<div class="new-editor-wrapper" >
											<div>
												{/* <h2 class="active-menu ml-3">Listings</h2> */}
												<div className="error-message"><b>To prevent data loss, make sure to click 'Save' button in every 5 minutes.</b></div>
												<div >
													<CKEditor
														editor={ClassicEditor}
														id="ckeditor1"
														data={userdata.users}


														config={{
															watchdog: false,
															title: false,
															heading_styles: {
																'font-size': '8px',
															},
															toolbar: {

																// shouldNotGroupWhenFull: true
															},
															htmlSupport: {
																allow: [
																	{
																		name: /.*/,
																		attributes: true,
																		classes: true,
																		styles: false,
																		title: false
																	}
																],
															},
															fontFamily: {
																options: [
																	'default',
																	'Times New Roman, Times, serif',
																	'Book Antiqua, serif',
																	'Arial, Helvetica, sans-serif',
																	'Courier New, Courier, monospace',
																	'Georgia, serif',
																	'Lucida Sans Unicode, Lucida Grande, sans-serif',
																	'Tahoma, Geneva, sans-serif',
																	'Trebuchet MS, Helvetica, sans-serif',
																	'Verdana, Geneva, sans-serif'

																]

															},
															fontSize: {
																options: [
																	'default',
																	9,
																	10,
																	11,
																	12,
																	13,
																	14,
																	15,
																	16,
																	17,
																	18,
																	19,
																	20,
																	21
																]
															},


														}}
														onInit={(editor) => {
															// You can store the "editor" and use when it is needed.
															// console.log("Editor is ready to use!", editor);

															editor.editing.view.change((writer) => {
																writer.setStyle(

																	editor.editing.view.document.getRoot()

																);
															});
														}
														}
														onReady={editor => {
															// You can store the "editor" and use when it is needed.
															setEditor(editor)

															console.log('Editor is ready to use!');
														}}


													/>

												</div>

												{showMessage && <div><b className="success-message">Certificate is created! You can close the editor safely!</b></div>}

											</div>
											{loading ? <CircularLoader /> : (
												<>
													{!showMessage &&
														<div >
															<div class="btn-container-multiple-editor" style={{ marginTop: "10px" }}>
																<button style={{ height: '28.5px' }} className="modal-close-btn" onClick={handleClose}><div style={{ marginTop: '-7.5px' }}>Close</div></button>
																{!showComplete && status !== "New" && (
																	<button style={{ height: '28.5px' }}
																		id="submit"
																		type="button"
																		value="Choose Date to Complete"

																		onClick={handleChooseDate}

																		className="modal-date-btn"

																	//onClick={()=>console.log(document.getElementById('cke_ckeditor').innerHTML)}
																	>
																		<div style={{ marginTop: '-7.5px' }}><b>Choose Date to Complete</b></div>
																	</button>
																)}
																{showComplete && !loading &&

																	<button style={{ height: '28.5px' }}
																		id="submit"
																		type="button"
																		value="Complete"

																		onClick={handleComplete}

																		className="modal-date-btn"

																	//onClick={()=>console.log(document.getElementById('cke_ckeditor').innerHTML)}
																	><div style={{ marginTop: '-7.5px' }}><b> Complete</b></div>
																	</button>
																}
																{status !== "New" && (

																	<button style={{ height: '28.5px' }}
																		class="modal-preview-btn"
																		onClick={handlePreviewPDF}
																	>
																		<div style={{ marginTop: '-7.5px' }}><b>Preview PDF</b></div>
																	</button>
																)}
																<button style={{ height: '28.5px' }}
																	class="modal-submit-btn"
																	id="submit"
																	type="button"
																	value="Submit"
																	onClick={handleSave}

																><div style={{ marginTop: '-7.5px' }}><b>{status === "New" ? "Save" : "Save"}</b></div></button>
															</div>
														</div>}
												</>
											)}
										</div>
									)}

								</main>
							</TabPanel>
						</Tabs>
					</div>
				</div >)
			}
			<div>

				<Modal isOpen={modalIsOpen} onRequestClose={closeModal} class="modal-complete">
					{isLoading && <div className="loader-container"><Loader /></div>} {/* Show loader */}
					<h4 className="form-heading" style={{ margin: '1px' }}>
						<b>Select Issued and Expiry Dates</b>
					</h4>
					<br />

					<label className="heading-template">
						<b>Issued Date:</b>
					</label>
					<TextField
						style={{ width: '100%' }}
						type="date"
						defaultValue={issuedDate ? dayjs(issuedDate).format('YYYY-MM-DD') : ""}
						onChange={(e) => setIssuedDate(e.target.value)}
						sx={{
							'.MuiOutlinedInput-root': { borderRadius: '10px !important' },
							'.MuiInputBase-input': {
								fontFamily: 'Segoe UI Historic !important',
								padding: '8px !important',
								textTransform: 'uppercase !important',
							},
							'.MuiFormLabel-root': {
								fontFamily: 'Segoe UI Historic !important',
								marginTop: '-6px !important',
								'&.MuiInputLabel-shrink': { marginTop: '0px !important' }
							}
						}}
						InputLabelProps={{ shrink: true }}
					/>

					<label className="heading-template">
						<b>Expiry Date:</b>
					</label>
					<TextField
						style={{ width: '100%' }}
						type="date"
						defaultValue={expiryDate ? dayjs(expiryDate).format('YYYY-MM-DD') : ""}
						onChange={(e) => setExpiryDate(e.target.value)}
						sx={{
							'.MuiOutlinedInput-root': { borderRadius: '10px !important' },
							'.MuiInputBase-input': {
								fontFamily: 'Segoe UI Historic !important',
								padding: '8px !important',
								textTransform: 'uppercase !important',
							},
							'.MuiFormLabel-root': {
								fontFamily: 'Segoe UI Historic !important',
								marginTop: '-6px !important',
								'&.MuiInputLabel-shrink': { marginTop: '0px !important' }
							}
						}}
						InputLabelProps={{ shrink: true }}
						inputProps={{
							min: issuedDate ? dayjs(issuedDate).format('YYYY-MM-DD') : ''
						}}
					/>


					<label className="heading-template"><b> Issued Place:</b></label>
					<TextField
						style={{ width: '100%' }}
						type="text"
						value={issuedPlace}
						onChange={(e) => setIssuedPlace(e.target.value)}
						placeholder="Issued Place"
						required
					/>

					<label className="heading-template"><b>Certificate Type:</b></label>
					<select
						style={{ width: '100%' }}
						className="select-dropdown select-template"
						value={certificateType}
						onChange={(e) => setCertificateType(e.target.value)}
						required
					>
						<option value="" className="select-template">Select Certificate Type</option>
						<option value="Interim" className="select-template">Interim</option>
						<option value="Full-Term" className="select-template">Full-Term</option>
						<option value="Short-Term" className="select-template">Short-Term</option>

					</select><br></br>



					<label className="heading-template"><b> Certificate Name:</b></label>
					<TextField
						style={{ width: '100%' }}
						type="text"
						value={certificateGoodName}
						onChange={(e) => setCertificateGoodName(e.target.value)}
						placeholder="Add Certificate Name"
						required
					/>
					{showCompleteMessage && <div><b className="error-message">{completeMessage}</b></div>}

					<div class="btn-container-multiple">
						<input
							id="back"
							type="button"
							value="Close"
							onClick={closeModal}
							className="modal-close-btn"
						/>
						{/* <button
							id="submit"
							type="button"
							value="Complete"
							onClick={handleDateSelection}
							className="modal-date-btn"

						//onClick={()=>console.log(document.getElementById('cke_ckeditor').innerHTML)}
						><div> Complete</div>
						</button> */}
						<input id="submit" type='submit' value="Complete"
							onClick={handleDateSelection} className='modal-date-btn'
						/>
					</div>
				</Modal>
			</div>
			{/* <footer>{"NeoCap Solutions"}</footer> */}
		</div >
	);
}




export default CertEditorSurveyor;