import './LeftNav.css'
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { React, useState, useEffect } from 'react';
import {
    FaBars,
    FaUserAlt,
    FaCommentAlt
} from "react-icons/fa";
import { FaRegCircleUser } from "react-icons/fa6";
import { LuUsers } from "react-icons/lu";
import { IoInformationCircleOutline } from "react-icons/io5";
import { IoDocumentsOutline } from "react-icons/io5";
import { FiShoppingBag } from "react-icons/fi";
import { MdApartment } from "react-icons/md";
import { BiCog, BiHelpCircle, BiBriefcase } from 'react-icons/bi';
import { TbMessages } from 'react-icons/tb';
import { NavLink, useLocation } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import SummarizeIcon from '@mui/icons-material/Summarize';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import PeopleIcon from '@mui/icons-material/People';
import PostAddIcon from '@mui/icons-material/PostAdd';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import ApartmentIcon from '@mui/icons-material/Apartment';
import AppsIcon from '@mui/icons-material/Apps';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';
import PreviewIcon from '@mui/icons-material/Preview';

import GridViewIcon from '@mui/icons-material/GridView';

const LeftNav = ({ }) => {
    const location = useLocation();
    const isMenuItemActive = (path) => location.pathname.includes(path);
    const [expandedMenu, setExpandedMenu] = useState(null);

    const handleMenuClick = (index) => {
        setExpandedMenu(expandedMenu === index ? null : index);
    };



    const menuItem = [
        {
            path: "/dashboard",
            name: "Dashboard",
            subpath: '/viewsurveys',
            icon: <GridViewIcon />,
            roles: ["Admin", "Project Manager", "Surveyor", "CertPro Admin"]
        },
        {
            path: "/register",
            name: "Register",
            icon: <HowToRegIcon />,
            roles: ["Admin", "CertPro Admin"]
        },
        {
            name: "Templates",
            subpath: '/choose-template',
            icon: <SummarizeIcon />,
            roles: ["Admin"],
            path: "/list-templates",
            // submenus: [
            //     {
            //         path: "/choose-template",
            //         name: "Create Template",
            //         icon: <NoteAddIcon />,
            //         roles: ["Admin"],

            //     },
            //     {
            //         path: "/list-templates",
            //         name: "List Templates Created",
            //         icon: <TextSnippetIcon />,
            //         roles: ["Admin"],
            //     },

            // ],
        },
        {
            name: "Survey",
            icon: <DashboardIcon />,
            roles: ["Admin", "Project Manager", "Surveyor", "CertPro Admin"],
            submenus: [
                {
                    path: "/survey-dashboard",
                    subpath: 'create-survey',
                    name: "Survey Dashboard",
                    icon: <CreateNewFolderIcon />,
                    roles: ["Admin", "Project Manager","CertPro Admin"]

                },
                {
                    path: "/surveyor-dashboard",
                    name: "Surveyor Dashboard",
                    subpath:'surveyorViewCertificates',
                    icon: <AccountTreeIcon />,
                    roles: ["Admin", "Project Manager", "Surveyor"],

                },
                {
                    path: "/approver-dashboard",
                    name: "Approver Dashboard",
                    subpath:'approverViewCertificates',
                    icon: <AssignmentTurnedInIcon />,
                    roles: ["Admin", "Project Manager", "CertPro Admin"],

                },
            ],
        },
        // {
        //     path: "/add-company-details",
        //     name: "Client Details",
        //     icon: <AddBusinessIcon />,
        //     roles: ["Admin", "Project Manager"]
        // },
        {
            name: "Administration",
            icon: <AppsIcon />,
            roles: ["Admin", "CertPro Admin"],
            submenus: [
                // {
                //     path: "/users",
                //     name: "Users",
                //     icon: <PeopleIcon />,
                //     roles: ["Admin", "Project Manager"]
                // },
                {
                    path: "/company",
                    subpath: '/addcompany',
                    name: "Clients",
                    icon: <ApartmentIcon />,
                    roles: ["Admin", "Project Manager", "CertPro Admin"]
                },
                // {
                //     path: "/clientVesselMap",
                //     name: "Client Vessel Mapping",
                //     icon: <ApartmentIcon />,
                //     roles: ["Admin", "Project Manager"]
                // },
                // {
                //     path: "/department",
                //     name: "Department",
                //     icon: <MilitaryTechIcon />,
                //     roles: ["Admin", "Project Manager"]
                // },
                {
                    path: "/vessel",
                    subpath: '/addvessel',
                    name: "Vessel",
                    icon: <DirectionsBoatIcon />,
                    roles: ["Admin", "Project Manager", "CertPro Admin"]
                },
                // {
                //     path: "/itemlist",
                //     name: "Item",
                //     icon: <PostAddIcon />,
                //     roles: ["Admin", "Project Manager"]
                // },
                // {
                //     path: "/requestlist",
                //     name: "Requests",
                //     icon: <MarkUnreadChatAltIcon />,
                //     roles: ["Admin", "Project Manager"]
                // },
                {
                    path: "/reset-password",
                    name: "Reset Password",
                    icon: <FaRegCircleUser />,
                    roles: ["Admin", "CertPro Admin"]
                }
            ],
        },
    ]
    useEffect(() => {
        // Check if the current location matches any submenu path
        const matchedSubmenuIndex = menuItem.findIndex(
            (item) => item.submenus && item.submenus.some((submenu) => location.pathname.includes(submenu.path))
        );
        const isAddVesselPage = location.pathname.includes('/addvessel');
        const isAddCompanyPage = location.pathname.includes('/addcompany');
        const isSurveyView = location.pathname.includes('/surveyorViewCertificates') || location.pathname.includes('/approverViewCertificates') || location.pathname.includes('/create-survey');



        const administrationIndex = menuItem.findIndex(item => item.name === 'Administration');
        const surveyIndex = menuItem.findIndex(item => item.name === 'Survey');


        if (isAddVesselPage) {
            setExpandedMenu(administrationIndex);
        }
        else if (isAddCompanyPage) {
            setExpandedMenu(administrationIndex);
        }
        else if(isSurveyView)
            setExpandedMenu(surveyIndex);

        else if (matchedSubmenuIndex !== -1) {
            if (userRole == "Surveyor" || userRole == "CertPro Admin") {
                setExpandedMenu(matchedSubmenuIndex - 1);
            }
            else if
                (userRole == "Project Manager") {
                setExpandedMenu(matchedSubmenuIndex - 2);
            }
            else
                setExpandedMenu(matchedSubmenuIndex);
        } else {
            setExpandedMenu(null);
        }
    }, [location.pathname]);

    const userRole = localStorage.getItem('role')
    const filteredMenuItems = menuItem.filter(item => {
        return item.roles.includes(userRole);
    });

    // Filter submenus based on user role
    const filteredSubmenus = filteredMenuItems.map(item => {
        if (item.submenus) {
            const filteredSubs = item.submenus.filter(submenu => {
                return submenu.roles.includes(userRole);
            });
            return { ...item, submenus: filteredSubs };
        }
        return item;
    });
    return (


        <TabList className="vertical-tab-list">
            <div className="container">
                {filteredSubmenus.map((item, index) => (
                    <div key={index} style={{ width: "100%" }}>
                        <NavLink to={item.path} className={`${((isMenuItemActive(item.path) || isMenuItemActive(item?.subpath)) && expandedMenu !== index) ? 'linkselectedmenu' : 'link'} `}
                            onClick={() => handleMenuClick(index)}
                        >
                            <div className="icon">{item.icon}</div>
                            <div className="link_text">{item.name}</div>
                        </NavLink>
                        {/* Render submenus if they exist */}
                        {item.submenus && expandedMenu === index && (
                            <div className="submenu-container">
                                {item.submenus.map((submenu, subIndex) => (
                                    <NavLink
                                        key={subIndex}
                                        to={submenu.path}
                                        className={`${(isMenuItemActive(submenu?.subpath) || isMenuItemActive(submenu.path)) ? 'linkselectedmenu' : 'link'}`}
                                    // activeClassName="active-leftNav"
                                    >
                                        <div className="icon">{submenu.icon}</div>
                                        <div className="link_text">{submenu.name}</div>
                                    </NavLink>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </TabList>


    );
}

export default LeftNav;
